import React from 'react';
import './OfertaSekcja2.css';
import { useTranslation } from 'react-i18next';

const OfertaSekcja2 = () => {
  const { t } = useTranslation();

  return (
    <section className="oferta-sekcja-2">
      <h2>{t('basic_package_title')}</h2>
      <div className="oferta-content">
        <div className="oferta-tekst">
          <h3>{t('activation_cost')}</h3>
          <p>{t('basic_package_activation_cost')}</p>
          <h3>{t('maintenance_cost')}</h3>
          <p>{t('basic_package_maintenance_cost')}</p>
        </div>
        <div className="oferta-funkcje">
          <h3>{t('features')}</h3>
          <ul>
            <li>{t('qr_menu')}</li>
            <li>{t('qr_code_ordering')}</li>
            <li>{t('menu_customization')}</li>
            <li>{t('daily_specials')}</li>
            <li>{t('takeaway_orders')}</li>
            <li>{t('call_waiter')}</li>
            <li>{t('request_bill')}</li>
            <li>{t('basic_statistics')}</li>
            <li>{t('google_reviews')}</li>
            <li>{t('technical_support')}</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default OfertaSekcja2;
