import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import plików tłumaczeń (jeśli trzymasz je lokalnie)
import translationEN from './locales/en/translation.json';
import translationPL from './locales/pl/translation.json';
import translationES from './locales/es/translation.json';

const resources = {
  en: { translation: translationEN },
  pl: { translation: translationPL },
  es: { translation: translationES },
};

i18n
  .use(HttpApi) // Ładowanie tłumaczeń z backendu (opcjonalne)
  .use(LanguageDetector) // Automatyczne wykrywanie języka
  .use(initReactI18next) // Integracja z React
  .init({
    resources, // Lokalnie załadowane tłumaczenia
    fallbackLng: 'en', // Domyślny język
    interpolation: {
      escapeValue: false, // Nie uciekać znaków (np. HTML)
    },
  });

export default i18n;
