import React, { useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'; // Import ikon dla hamburgera
import { faBoxOpen, faFilePdf, faHandshake, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [showLanguages, setShowLanguages] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Nowy stan

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setShowLanguages(false);
  };

  const currentLanguage = i18n.language.toUpperCase();

  return (
    <header className="header">
      <Link to="/">
        <img src="logo512.png" className="logoHeader" alt="Logo Feliztrade" />
      </Link>
      <div className="menu-toggle" onClick={toggleMenu}>
        <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
      </div>
      <nav className={`menu ${isMenuOpen ? 'menu-open' : ''}`}>
        <div className="row justify-content-center g-2">
          <div className="col-6 col-md-3">
            <Link className="btn btn-outline-light w-100 text-center" to="/Oferta">
              <FontAwesomeIcon icon={faBoxOpen} /> {t('offer')}
            </Link>
          </div>
          <div className="col-6 col-md-3">
            <Link className="btn btn-outline-light w-100 text-center" to="/Opis">
              <FontAwesomeIcon icon={faFilePdf} /> {t('description')}
            </Link>
          </div>
          <div className="col-6 col-md-3">
            <Link className="btn btn-outline-light w-100 text-center" to="/Kontakt">
              <FontAwesomeIcon icon={faHandshake} /> {t('contact')}
            </Link>
          </div>
          <div className="col-6 col-md-3">
            <Link className="btn btn-outline-light w-100 text-center" to="https://restaurantfeliz-demo.onrender.com/admin">
              <FontAwesomeIcon icon={faChartLine} /> {t('demo')}
            </Link>
          </div>
        </div>

      </nav>
      <div className="language-toggle">
        <div
          className="language-display"
          onClick={() => setShowLanguages(!showLanguages)}
        >
          {currentLanguage}
          <span className={`arrow ${showLanguages ? 'up' : 'down'}`}></span>
        </div>
        {showLanguages && (
          <div className="language-menu">
            <button onClick={() => changeLanguage('en')}><span>🇬🇧</span> English</button>
            <button onClick={() => changeLanguage('es')}><span>🇪🇸</span> Español</button>
            <button onClick={() => changeLanguage('pl')}><span>🇵🇱</span> Polski</button>

          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
