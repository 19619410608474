import React from 'react';
import './OfertaSekcja3.css';
import { useTranslation } from 'react-i18next';

const OfertaSekcja3 = () => {
  const { t } = useTranslation();

  return (
    <section className="oferta-sekcja-3">
      <div className="obrazek">
        <img src="/photo-waiters.png" alt={t('image_description')} />
      </div>
      <div className="tekst">
        <h2>{t('more_info_title')}</h2>
        <h3>{t('installation_time_title')}</h3>
        <p>{t('installation_time_description')}</p>
        <h3>{t('getting_started_title')}</h3>
        <p>{t('getting_started_description')}</p>
        <h3>{t('contact_now_title')}</h3>
        <p><strong>{t('phone_label')}:</strong> <a href="tel:+48502600739">{t('phone_number')}</a></p>
        <p><strong>{t('email_label')}:</strong> <a href="mailto:FelizTradeLTD@proton.me">{t('email_address')}</a></p>
      </div>
    </section>
  );
};

export default OfertaSekcja3;
