import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Kontakt.css';
import { useTranslation } from 'react-i18next';

function Kontakt() {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    email: '',
    telefon: '',
    nazwaFirmy: '',
    wiadomosc: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = 'service_s3id2kq';
    const templateID = 'template_huwxi9c';
    const userID = 'TcbeXr_B5CXkoQgTW';

    emailjs
      .send(
        serviceID,
        templateID,
        {
          from_name: formData.nazwaFirmy || t('anonymous'),
          reply_to: formData.email,
          phone: formData.telefon,
          message: formData.wiadomosc,
        },
        userID
      )
      .then(
        (result) => {
          console.log(t('email_sent'), result.text);
          alert(t('message_sent'));
          setFormData({
            email: '',
            telefon: '',
            nazwaFirmy: '',
            wiadomosc: '',
          });
        },
        (error) => {
          console.error(t('email_error'), error.text);
          alert(t('message_failed'));
        }
      );
  };

  return (
    <div className="kontakt">
      <h1>{t('contact_us')}</h1>
      <p className="intro">{t('contact_intro')}</p>

      <div className="kontakt-info">
        <h2>{t('contact_details')}</h2>
        <p><strong>{t('address')}:</strong> Preston Lancashire, United Kingdom</p>
        <p><strong>{t('phone')}:</strong> +48 502 600 739</p>
        <p><strong>{t('email')}:</strong> FelizTradeLTD@proton.me</p>

      </div>

      <div className="kontakt-formularz">
        <h2>{t('contact_form')}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="nazwaFirmy">{t('company_name')}:</label>
            <input
              type="text"
              id="nazwaFirmy"
              name="nazwaFirmy"
              placeholder={t('company_name_placeholder')}
              value={formData.nazwaFirmy}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">{t('email')}:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder={t('email_placeholder')}
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="telefon">{t('phone')}:</label>
            <input
              type="tel"
              id="telefon"
              name="telefon"
              placeholder={t('phone_placeholder')}
              value={formData.telefon}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="wiadomosc">{t('message')}:</label>
            <textarea
              id="wiadomosc"
              name="wiadomosc"
              rows="5"
              placeholder={t('message_placeholder')}
              value={formData.wiadomosc}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit">{t('send_message')}</button>
        </form>
      </div>
    </div>
  );
}

export default Kontakt;
