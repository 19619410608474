import React from 'react';
import './OfertaSekcja1.css';
import { useTranslation } from 'react-i18next';

const OfertaSekcja1 = () => {
  const { t } = useTranslation();

  return (
    <section className="oferta-sekcja-1">
      <div className="tekst">
        <h2>{t('restaurant_app_title')}</h2>
        <p>{t('restaurant_app_description')}</p>
      </div>
      <div className="obrazek">
        <img src="/waiter-taps.png" alt={t('image_description')} />
      </div>
    </section>
  );
};

export default OfertaSekcja1;
