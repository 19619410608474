import React from 'react';
import './OfertaSekcja2.css';
import { useTranslation } from 'react-i18next';

const OfertaSekcja2 = () => {
  const { t } = useTranslation();

  return (
    <section className="oferta-sekcja-2">
      <h2>{t('premium_package_title')}</h2>
      <div className="oferta-content">
        <div className="oferta-tekst">
          <h3>{t('activation_cost')}</h3>
          <p>{t('premium_package_activation_cost')}</p>
          <h3>{t('maintenance_cost')}</h3>
          <p>{t('premium_package_maintenance_cost')}</p>
        </div>
        <div className="oferta-funkcje">
          <h3>{t('features')}</h3>
          <ul>
            <li><strong>{t('all_standard_features')}</strong></li>
            <li>{t('online_reservations')}</li>
            <li>{t('online_orders')}</li>
            <li>{t('custom_features')}</li>
            <li>{t('advanced_statistics')}</li>
            <li>{t('technical_support')}</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default OfertaSekcja2;
