import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Importowanie Routera i tras
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Uni from './components/Uni/Uni';
import Kontakt from './components/Kontakt/Kontakt'; // Import komponentu
import Opis from './components/Opis/Opis';
import OfertaSekcja1_1 from './components/Oferta/OfertaSekcja1_1';
import OfertaSekcja2_1 from './components/Oferta/OfertaSekcja2_1';
import OfertaSekcja2_2 from './components/Oferta/OfertaSekcja2_2';
import OfertaSekcja2_3 from './components/Oferta/OfertaSekcja2_3';
import OfertaSekcja3_1 from './components/Oferta/OfertaSekcja3_1';

// import Podstrona4 from './components/Podstrona4';
import './App.css';




function App() {



  return (
    <Router>
      <div className="App">
        <Uni /> {/* Element stały, wyświetlany zawsze */}
        <Header /> {/* Nagłówek, który jest widoczny na każdej podstronie */}
        <Routes>
          <Route path="/" element={<Hero />} /> {/* Strona główna */}
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/opis" element={<Opis />} />
          <Route path="/oferta" element={
            <div>
              <OfertaSekcja1_1 />
              <OfertaSekcja2_1 />
              <OfertaSekcja2_2 />
              <OfertaSekcja2_3 />
              <OfertaSekcja3_1 />
            </div>
          } />
        </Routes>

      </div>
    </Router>
  );
}

export default App;
